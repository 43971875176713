

























import {Component, Vue, Prop} from 'vue-property-decorator';
@Component({
    components: {  
    }
})
export default class TableShimmer extends Vue{
    @Prop({default: 5, required: false}) readonly headers!: number
    @Prop({default: 10, required: false}) readonly items!: number
    @Prop({default: true, required: false}) readonly showHeaders!: boolean

}
