
























import {Component, Vue, Prop, Emit} from 'vue-property-decorator'
import {ITableHeader} from '@/interfaces/common'
import TableShimmer from '@/components/common/Shimmer/TableShimmer.vue'
@Component({
    components: {
        TableShimmer
    }
})
export default class DataTable extends Vue {
    private page: number = 1
    @Prop({required: true}) readonly headers !: Array<ITableHeader>
    @Prop({required: false}) readonly items !: Array<any>
    @Prop({required: false, default: 25}) readonly itemsPerPage !: number
    @Prop({required: false, default: 0}) readonly pageCount !: number
    @Prop({required: true, default: 0}) readonly totalItems !: number
    @Prop({required: false, default: false}) readonly loading !: boolean
    @Prop({required: false, default: ""}) readonly search !: string
    @Emit("changePage")
    changePage(){
        return this.page;
    }
}
